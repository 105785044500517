import { Component, Input, OnInit } from '@angular/core';
import {
  AdmissionStatus,
  ShowedAdmissionStatus,
} from 'app/services/api/enums/admission-status';
import { snakeToTitle } from '../utils/string-conversion';

@Component({
  selector: 'status-badge',
  template: `<span
    class="w-auto px-3 py-1 rounded uppercase text-sm font-bold"
    [ngClass]="{
      'bg-cyan-100 text-cyan-600': cyan.includes(status),
      'bg-blue-100 text-blue-600': blue.includes(status),
      'bg-emerald-100 text-emerald-600': emerald.includes(status),
      'bg-gray-100 text-gray-600': gray.includes(status),
      'bg-teal-100 text-teal-600': teal.includes(status),
      'bg-red-100 text-red-600': red.includes(status),
      'bg-indigo-100 text-indigo-600': indigo.includes(status),
      'bg-violet-100 text-violet-600': violet.includes(status),
      'bg-amber-100 text-amber-600': amber.includes(status),
      'bg-purple-100 text-purple-600': purple.includes(status)
    }"
  >
    {{ status }}
  </span>`,
})
export class StatusComponent implements OnInit {
  @Input() state: string;

  public cyan = ['NEW', 'REQUEST CREATED', ShowedAdmissionStatus.NEW_REFERRAL];
  public blue = [
    'WAITING SUBMISSION',
    'GRADUATED',
    ShowedAdmissionStatus.WAITLIST_FOR_PLACEMENT,
  ];
  public emerald = [
    'PARENTS SUBMITTED',
    'PARENT SUBMITTED',
    'ACTIVE',
    'PRESENT',
    'SUBMITTED',
    'APPROVED',
    'STARTED',
    'ACCEPTED',
    'OPEN',
    'YES',
  ];
  public gray = [
    'SUBMISSION EXPIRED',
    'INACTIVE',
    'CANCEL',
    'ENDED',
    'CANCELLED',
    'CANCELED',
    ShowedAdmissionStatus.CANCELED,
    'NO',
  ];
  public teal = ['ASSESSED ELIGIBLE', 'COMMUNICATED', 'COMPLETED', 'COMPLETE'];
  public red = [
    'ASSESSED NOT ELIGIBLE',
    'ABSENT',
    'PLACEMENT REJECTED',
    'DECLINED',
    'CLOSE',
    ShowedAdmissionStatus.EXPIRED,
  ];
  public indigo = [
    'ASSESSED WAITLIST',
    'FEE FILLED',
    'IN REVIEW',
    'WAITING PARENT SIGNATURE',
    'WAITING PARENT TO SUBMIT',
  ];
  public violet = [
    'PLACEMENT OFFERED',
    'PENDING UPDATE',
    ShowedAdmissionStatus.PENDING_UPDATE,
  ];
  public amber = [
    'DRAFT',
    'PLACEMENT DRAFT',
    'NOT STARTED',
    ShowedAdmissionStatus.WAITLIST_FOR_NEEDS_ASSESSMENT,
  ];
  public purple = ['PLACEMENT ACCEPTED', 'WAITING CM SIGNATURE'];

  status: string = '';

  ngOnInit(): void {
    this.status = snakeToTitle(this.state.toUpperCase());
  }
}
