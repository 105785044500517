import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { DeepPartial } from './models/util';
import { Pagination } from './models/pagination';

@Injectable({
  providedIn: 'any',
})
export class Api<T> {
  protected headers = {};
  protected base = '';
  protected _resource: string;
  protected token = localStorage.getItem('accessToken') ?? '';

  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', `Bearer ${this.token}`);
    this.base = environment.apiUrl;

    if (this.base === '') {
      console.error('API URL is not defined in environment.ts');
    }
  }

  public set resource(resource: string) {
    this._resource = resource;
  }

  public get resource() {
    return this._resource;
  }

  public list = (query: any): Observable<ListResponse<T>> =>
    this.http.get<ListResponse<T>>(`${this.base}/${this._resource}`, {
      headers: this.headers,
      params: query,
    });

  public getWithBody = (payload: any): Observable<ListResponse<T>> =>
    this.http.put<ListResponse<T>>(
      `${this.base}/${this._resource}`,
      payload,
      this.headers
    );

  public update = (id: string, payload: any): Observable<OneResponse<T>> =>
    this.http.put<OneResponse<T>>(
      `${this.base}/${this._resource}/${id}`,
      payload,
      this.headers
    );

  public getById = (id: string) =>
    this.http.get<OneResponse<T>>(
      `${this.base}/${this._resource}/${id}`,
      this.headers
    );

  public deleteById = (id: string) =>
    this.http.delete<OneResponse<null>>(
      `${this.base}/${this._resource}/${id}`,
      this.headers
    );

  public create = (payload: any): Observable<OneResponse<T>> =>
    this.http
      .post<T>(`${this.base}/${this._resource}`, payload, this.headers)
      .pipe(
        map((res) => ({
          data: res,
        }))
      );

  public getFile = (query: any) =>
    this.http.get(`${this.base}/${this._resource}`, {
      responseType: 'blob' as 'json', // Receive response as Blob
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: new HttpParams({
        fromString: query,
      }),
    });
}

export interface ListResponse<T> {
  data: T[];
  meta: Pagination;
}

export interface OneResponse<T> {
  data: T;
}
