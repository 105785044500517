<div>
  <div class="w-fit flex flex-col" *ngIf="file">
    <section class="flex items-center w-full">
      <!-- File Icon -->
      <span class="grid place-items-center p-2">
        <img [src]="icon" alt="file" [class]="iconWidth" />
      </span>

      <!-- Description -->
      <span class="flex flex-col break-words max-w-100">
        <div class="flex flex-row items-center">{{ fileName }}</div>
        <p *ngIf="size || date" class="text-sm text-gray-500">
          <span *ngIf="size">{{ fileSize }}</span>
          <span *ngIf="size && date"> | </span>
          <span *ngIf="date">{{ date }}</span>
        </p>
      </span>
    </section>

    <!-- Download File -->
    <section *ngIf="showDownloadButton">
      <a
        class="text-blue-500 font-semibold mx-2 cursor-pointer"
        [href]="file | secure | async"
        [download]="fileName"
        target="_blank"
      >
        Download
      </a>
    </section>
  </div>
</div>
