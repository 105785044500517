import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'attachment-preview',
  templateUrl: './attachment-preview.component.html',
})
export class AttachmentPreviewComponent implements OnInit {
  @Input() file: string;
  @Input() size: number;
  @Input() date: string;
  @Input() showDownloadButton: boolean = true;
  @Input() iconWidth: string = 'w-10';

  fileName: string;
  extension: string;
  fileSize: string;
  icon: string;

  ngOnInit() {
    this.fileName = this.getFileName();
    this.extension = this.getFileExtension();
    this.fileSize = this.formatFileSize();

    if (this.extension === 'pdf') {
      this.icon = 'assets/images/extensions/pdf-icon.svg';
    } else if (this.extension === 'doc' || this.extension === 'docx') {
      this.icon = 'assets/images/extensions/doc-icon.svg';
    } else if (this.extension === 'xls' || this.extension === 'xlsx') {
      this.icon = 'assets/images/extensions/xls-icon.svg';
    } else {
      this.icon = 'assets/images/extensions/undefined-icon.svg';
    }
  }

  getFileExtension() {
    const segments = this.file.split('/');
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i];
      if (segment.includes('.')) {
        return segment.split('.').pop();
      }
    }
    return '';
  }

  getFileName() {
    const segments = this.file.split('/');
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i];
      if (segment.includes('.')) {
        return segment;
      }
    }
    return '';
  }

  async downloadFile() {
    try {
      const res = await fetch(this.file);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.fileName;
      document.body.appendChild(a);
      a.click();
      a.remove(); // Optional: remove the anchor element from the document body
    } catch (e) {
      console.error(e);
    }
  }

  // convert size to bytes, KB, MB, or GB
  formatFileSize(): string {
    if (this.size < 1024) {
      return this.size + ' bytes';
    } else if (this.size < 1048576) {
      return (this.size / 1024).toFixed(2) + ' KB';
    } else if (this.size < 1073741824) {
      return (this.size / 1048576).toFixed(2) + ' MB';
    } else {
      return (this.size / 1073741824).toFixed(2) + ' GB';
    }
  }
}
