import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import VimeoPlayer from '@vimeo/player';

@Component({
  selector: 'vimeo-video',
  template: ` <div></div> `,
})
export class VimeoVideoComponent implements OnInit {
  @Input() videoId: string;
  public vimeoPlayer: VimeoPlayer;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const options = {
      id: this.videoId,
      autopause: 0, // Set to 0 to prevent pausing other players on the page
    };

    this.vimeoPlayer = new VimeoPlayer(
      this.elementRef.nativeElement.querySelector('div'),
      options
    );
  }
}
