import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'weekly-schedule',
  templateUrl: './weekly-schedule.component.html',
})
export class WeeklyScheduleComponentComponent implements OnInit {
  @Input() checkedDays: Array<number>;

  days = [
    { id: 1, name: 'Sunday', label: 'S' },
    { id: 2, name: 'Monday', label: 'M' },
    { id: 3, name: 'Tuesday', label: 'T' },
    { id: 4, name: 'Wednesday', label: 'W' },
    { id: 5, name: 'Thursday', label: 'T' },
    { id: 6, name: 'Friday', label: 'F' },
    { id: 7, name: 'Saturday', label: 'S' },
  ];

  ngOnInit(): void {}
}
