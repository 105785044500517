import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-dropdown',
  templateUrl: './button-dropdown.component.html',
})
export class ButtonDropdownComponent implements OnInit {
  @Input() options = [];

  ngOnInit() {}
}
