import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { Setting } from './models/setting';

@Injectable({
  providedIn: 'any',
})
export class ApiSetting extends Api<Setting> {
  constructor(http: HttpClient) {
    super(http);
    this.resource = 'settings';
  }
}
