import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboard'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed-in user to the '/dashboard'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'parent/e-admission',
        loadChildren: () =>
          import('app/modules/parent/e-admission/e-admission.module').then(
            (m) => m.EadmissionModule
          ),
      },
      {
        path: 'parent/placement-confirmation',
        loadChildren: () =>
          import(
            'app/modules/parent/placement-confirmation/placement-confirmation.module'
          ).then((m) => m.PlacementConfirmationModule),
      },
      {
        path: 'parent/transfer-to-ds-plus',
        loadChildren: () =>
          import(
            'app/modules/parent/transfer-ds-plus/transfer-ds-plus.module'
          ).then((m) => m.TransferDsPlusModule),
      },
      {
        path: 'parent/withdrawal-form',
        loadChildren: () =>
          import('app/modules/parent/withdrawal/withdrawal.module').then(
            (m) => m.WithdrawalModule
          ),
      },
      {
        path: 'parent/family-interview-form',
        loadChildren: () =>
          import(
            'app/modules/parent/family-interview/family-interview-parent.module'
          ).then((m) => m.FamilyInterviewParentModule),
      },
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'example',
        loadChildren: () =>
          import('app/modules/admin/example/example.module').then(
            (m) => m.ExampleModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/admin/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'admission',
        loadChildren: () =>
          import('app/modules/admin/admission/admission.module').then(
            (m) => m.AdmissionModule
          ),
      },
      {
        path: 'admission/admission-process',
        loadChildren: () =>
          import('app/modules/admin/admission/admission.module').then(
            (m) => m.AdmissionModule
          ),
      },
      {
        path: 'admission/admission-process/:id',
        loadChildren: () =>
          import('app/modules/admin/admission/admission.module').then(
            (m) => m.AdmissionModule
          ),
      },
      {
        path: 'students',
        loadChildren: () =>
          import('app/modules/admin/student/student.module').then(
            (m) => m.StudentModule
          ),
      },
      {
        path: 'transfer-ds-plus',
        loadChildren: () =>
          import(
            'app/modules/admin/transfer-ds-plus/transfer-ds-plus.module'
          ).then((m) => m.TransferDsPlusModule),
      },
      {
        path: 'classrooms',
        loadChildren: () =>
          import('app/modules/admin/classroom/classroom.module').then(
            (m) => m.ClassroomModule
          ),
      },
      {
        path: 'broadcasts',
        loadChildren: () =>
          import('app/modules/admin/broadcast/broadcast.module').then(
            (m) => m.BroadcastModule
          ),
      },

      {
        path: 'chat-history',
        loadChildren: () =>
          import('app/modules/admin/chat-history/chat-history.module').then(
            (m) => m.ChatHistoryModule
          ),
      },

      {
        path: 'schedules',
        loadChildren: () =>
          import('app/modules/admin/schedules/classroom-schedule.module').then(
            (m) => m.ClassroomScheduleModule
          ),
      },
      {
        path: 'parents',
        loadChildren: () =>
          import('app/modules/admin/parent/parent.module').then(
            (m) => m.ParentModule
          ),
      },
      {
        path: 'teachers',
        loadChildren: () =>
          import('app/modules/admin/teacher/teacher.module').then(
            (m) => m.TeacherModule
          ),
      },
      {
        path: 'staff',
        loadChildren: () =>
          import('app/modules/admin/staff/staff.module').then(
            (m) => m.StaffModule
          ),
      },
      {
        path: 'attendances',
        loadChildren: () =>
          import('app/modules/admin/attendance/attendance.module').then(
            (m) => m.AttendanceModule
          ),
      },
      {
        path: 'academic-years',
        loadChildren: () =>
          import(
            'app/modules/admin/academic/academic-year/academic-year.module'
          ).then((m) => m.AcademicModule),
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('app/modules/admin/academic/term/term.module').then(
            (m) => m.TermModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('app/modules/admin/report/report.module').then(
            (m) => m.ReportModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('app/modules/admin/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'withdrawals',
        loadChildren: () =>
          import('app/modules/admin/withdrawal/withdrawal.module').then(
            (m) => m.WithdrawalModule
          ),
      },

      {
        path: 'graduates',
        loadChildren: () =>
          import('app/modules/admin/graduate/graduate.module').then(
            (m) => m.GraduateModule
          ),
      },
      {
        path: 'iep',
        loadChildren: () =>
          import('app/modules/admin/IEP/iep.module').then((m) => m.IepModule),
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('app/modules/admin/finance/finance.module').then(
            (m) => m.FinanceModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('app/modules/admin/event-calendar/event-calendar.module').then(
            (m) => m.EventCalendarModule
          ),
      },
      {
        path: 'audit-trails',
        loadChildren: () =>
          import('app/modules/admin/audit-trail/audit-trail.module').then(
            (m) => m.AuditTrailModule
          ),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('app/modules/admin/auth/auth.module').then(
            (m) => m.AuthModule
          ),
      },
    ],
  },
];
