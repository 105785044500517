import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'autocomplete-input',
  template: `<div class="mb-4 form-group">
    <label
      *ngIf="label"
      [for]="controlName"
      class="block text-sm font-medium text-gray-700"
      >{{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>

    <input
      [formControl]="controlName"
      matInput
      [name]="controlName"
      [placeholder]="placeholder"
      class="w-full border border-gray-300 py-2 px-4 h-9 rounded-md form-control"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="optionSelected.emit($event)"
      (displayWith)="displayFn($event)"
    >
      <ng-container *ngIf="filteredOptions.length > 0; else notFoundTemplate">
        <mat-option
          *ngFor="let option of filteredOptions"
          [value]="optionValue !== '' ? option[optionValue] : option"
        >
          {{ optionLabel !== '' ? option[optionLabel] : option }}
        </mat-option>
      </ng-container>
      <ng-template #notFoundTemplate>
        <mat-option disabled class="!opacity-100"> No Data Found </mat-option>
      </ng-template>
    </mat-autocomplete>
    <form-validator [controlName]="controlName"></form-validator>
  </div> `,
})
export class AutocompleteInputComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() options = [];
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  public displayFn(option: any): string {
    return option && this.optionLabel ? option[this.optionLabel] : option;
  }

  public get filteredOptions(): any[] {
    const uniqueOptions = this.options.filter((option, index, self) => {
      const optionValue =
        this.optionValue !== '' ? option[this.optionValue] : option;
      return (
        index ===
        self.findIndex((o) => o && this.getOptionValue(o) === optionValue)
      );
    });

    return uniqueOptions;
  }

  private getOptionValue(option: any): any {
    return option && this.optionValue ? option[this.optionValue] : option;
  }

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
