import { Attendance } from 'app/services/api/models/attendance';
import { StaffListComponent } from './../../../modules/admin/staff/staff-list.component';
/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

const fixedNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'feather:home',
    link: '/dashboard',
  },
  {
    id: 'admission',
    title: 'E-Admission',
    type: 'basic',
    icon: 'mat_outline:list',
    link: '/admission',
  },
  {
    id: 'staff',
    title: 'Staff',
    type: 'basic',
    icon: 'mat_outline:group',
    link: '/staff',
  },
  {
    id: 'attendances',
    title: 'Attendance',
    type: 'basic',
    icon: 'mat_outline:ballot',
    link: '/attendances',
  },
  {
    id: 'students.group',
    title: 'Students',
    type: 'collapsable',
    icon: 'mat_outline:person',
    children: [
      {
        id: 'students',
        title: 'Students',
        type: 'basic',
        icon: 'mat_outline:person',
        link: '/students',
      },
      {
        id: 'iep',
        title: 'Reports',
        type: 'basic',
        icon: 'mat_outline:note',
        link: '/iep',
      },
      {
        id: 'withdrawals',
        title: 'Withdrawals',
        type: 'basic',
        icon: 'mat_outline:backspace',
        link: '/withdrawals',
      },
      {
        id: 'graduates',
        title: 'Graduates',
        type: 'basic',
        icon: 'mat_outline:school',
        link: '/graduates',
      },
    ],
  },
  {
    id: 'plannings.group',
    title: 'Events',
    type: 'basic',
    icon: 'mat_outline:edit_calendar',
    link: '/events',
  },
  {
    id: 'classrooms.group',
    title: 'Classes',
    type: 'collapsable',
    icon: 'mat_outline:school',
    children: [
      {
        id: 'classrooms',
        title: 'Classes',
        type: 'basic',
        icon: 'mat_outline:school',
        link: '/classrooms',
      },
      {
        id: 'schedules',
        title: 'Class Sessions',
        type: 'basic',
        icon: 'mat_outline:school',
        link: '/schedules',
      },
    ],
  },
  {
    id: 'communications.group',
    title: 'Communications',
    type: 'collapsable',
    icon: 'heroicons_outline:speakerphone',
    children: [
      {
        id: 'broadcasts',
        title: 'Broadcasts',
        type: 'basic',
        icon: 'heroicons_outline:speakerphone',
        link: '/broadcasts',
      },
      {
        id: 'chat-history',
        title: 'Chat History',
        type: 'basic',
        icon: 'heroicons_outline:chat-alt-2',
        link: '/chat-history',
      },
    ],
  },
  // {
  //   id: 'parent',
  //   title: 'Parents',
  //   type: 'basic',
  //   icon: 'mat_outline:supervised_user_circle',
  //   link: '/parents',
  // },
  {
    id: 'reports',
    title: 'Reports',
    type: 'basic',
    icon: 'mat_outline:bar_chart',
    link: '/reports',
  },
  {
    id: 'finance',
    title: 'Finance',
    type: 'basic',
    icon: 'heroicons_outline:currency-dollar',
    link: '/finance',
  },

  {
    id: 'academics.group',
    title: 'Academic Years',
    type: 'collapsable',
    icon: 'mat_outline:description',
    children: [
      {
        id: 'academic-years',
        title: 'Years',
        type: 'basic',
        icon: 'mat_outline:description',
        link: '/academic-years',
      },
      {
        id: 'terms',
        title: 'Terms',
        type: 'basic',
        icon: 'mat_outline:description',
        link: '/terms',
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'basic',
    icon: 'mat_outline:settings',
    link: '/settings',
  },
];

export const defaultNavigation: FuseNavigationItem[] = fixedNavigation;
export const compactNavigation: FuseNavigationItem[] = fixedNavigation;
export const futuristicNavigation: FuseNavigationItem[] = fixedNavigation;
export const horizontalNavigation: FuseNavigationItem[] = fixedNavigation;
