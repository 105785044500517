import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { EipicProfile } from './models/eipic-profile';

@Injectable({
  providedIn: 'any',
})
export class ApiEipicProfile extends Api<EipicProfile> {
  constructor(http: HttpClient) {
    super(http);
    this.resource = 'eipic-profiles';
  }
}
