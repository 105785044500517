<div class="flex flex-row gap-2 items-center">
  <div
    *ngIf="name"
    class="w-8 aspect-square rounded-full grid place-items-center text-amber-600 text-2xl font-bold bg-amber-200"
  >
    {{ this.name.charAt(0).toUpperCase() }}
  </div>
  <section class="flex flex-col gap-px">
    <span>{{ name }}</span>
    <span class="text-gray-700 text-sm">{{ date }}</span>
  </section>
</div>
