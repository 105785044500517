import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BreadcrumbService,
  BreadcrumbItem,
} from 'app/services/breadcrumb.service';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { openSnackBar } from 'app/shared/utils/snackbar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateInterface } from 'app/interfaces/create.interface';
import { Staff } from 'app/services/api/models/staff';
import { ApiStaff } from 'app/services/api/api-staff.service';
import { EipicProfileAction } from 'app/services/api/enums/eipic-profile-action';
import { ApiEipicProfile } from 'app/services/api/api-eipic-profile.service';
import { EipicProfile } from 'app/services/api/models/eipic-profile';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Location } from '@angular/common';

export const MONTH_MODE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'other-services-form',
  templateUrl: './other-services-form.component.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_MODE_FORMATS }],
})
export class OtherServiceFormComponent implements OnInit, CreateInterface {
  public preSchool: FormArray;
  public occupationTherapy: FormArray;
  public speechAndLanguageTherapy: FormArray;
  public others: FormArray;

  public eipicProfile: EipicProfile;

  private otherServicesId: string;
  private routeSub: Subscription;
  public staff: Staff[] = [];
  public staffOptions = [];
  public resource: EipicProfile;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private apiEipicProfile: ApiEipicProfile,
    private snackBar: MatSnackBar,
    private location: Location,
    private apiStaff: ApiStaff,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      this.otherServicesId = params.id;

      this.initBreadcrumb();
      if (this.otherServicesId) {
        this.getOtherServices();
      }
    });
  }

  public monthSelected(normalizedMonthAndYear, datepicker): void {
    datepicker.close();
  }

  public addForm(
    type: string,
    data?: {
      year: string;
      frequency: string;
      remarks: string;
      ratio: string;
      provider: string;
      email_text_done: boolean;
    }
  ): void {
    const helperForm = new FormGroup({
      year: new FormControl(data ? data.year : ''),
      frequency: new FormControl(data ? data.frequency : ''),
      remarks: new FormControl(data ? data.remarks : ''),
      ratio: new FormControl(data ? data.ratio : ''),
      provider: new FormControl(data ? data.provider : ''),
      email_text_done: new FormControl(data ? data.email_text_done : false),
    });

    switch (type) {
      case 'pre_school':
        this.preSchool.push(helperForm);
        break;
      case 'occupation_therapy':
        this.occupationTherapy.push(helperForm);
        break;
      case 'speech_and_language_therapy':
        this.speechAndLanguageTherapy.push(helperForm);
        break;
      case 'others':
        this.others.push(helperForm);
        break;
    }
  }

  public removeForm(type: string, index: number): void {
    switch (type) {
      case 'pre_school':
        this.preSchool.removeAt(index);
        break;
      case 'occupation_therapy':
        this.occupationTherapy.removeAt(index);
        break;
      case 'speech_and_language_therapy':
        this.speechAndLanguageTherapy.removeAt(index);
        break;
      case 'others':
        this.others.removeAt(index);
        break;
    }
  }

  public getOtherServices(): void {
    this.apiEipicProfile.getById(this.otherServicesId).subscribe((res) => {
      this.resource = res.data;
      this.initBreadcrumb();

      if (this.resource.other_services) {
        this.preSchool.clear();
        this.occupationTherapy.clear();
        this.speechAndLanguageTherapy.clear();
        this.others.clear();

        this.resource.other_services.pre_school.forEach((element) => {
          this.addForm('pre_school', element);
        });

        this.resource.other_services.occupation_therapy.forEach((element) => {
          this.addForm('occupation_therapy', element);
        });

        this.resource.other_services.speech_and_language_therapy.forEach(
          (element) => {
            this.addForm('speech_and_language_therapy', element);
          }
        );

        this.resource.other_services.others.forEach((element) => {
          this.addForm('others', element);
        });
      }
    });
  }

  public initForm(): void {
    this.preSchool = new FormArray([
      new FormGroup({
        year: new FormControl(''),
        frequency: new FormControl(''),
        remarks: new FormControl(''),
        ratio: new FormControl(''),
        provider: new FormControl(''),
        email_text_done: new FormControl(false),
      }),
    ]);

    this.occupationTherapy = new FormArray([
      new FormGroup({
        year: new FormControl(''),
        frequency: new FormControl(''),
        remarks: new FormControl(''),
        ratio: new FormControl(''),
        provider: new FormControl(''),
        email_text_done: new FormControl(false),
      }),
    ]);

    this.speechAndLanguageTherapy = new FormArray([
      new FormGroup({
        year: new FormControl(''),
        frequency: new FormControl(''),
        remarks: new FormControl(''),
        ratio: new FormControl(''),
        provider: new FormControl(''),
        email_text_done: new FormControl(false),
      }),
    ]);

    this.others = new FormArray([
      new FormGroup({
        year: new FormControl(''),
        frequency: new FormControl(''),
        remarks: new FormControl(''),
        ratio: new FormControl(''),
        provider: new FormControl(''),
        email_text_done: new FormControl(false),
      }),
    ]);
  }

  public initBreadcrumb(): void {
    let breadcrumb: BreadcrumbItem;

    if (this.resource && this.resource.other_services) {
      breadcrumb = {
        title: 'Edit Other Services',
        breadcrumbs: [
          {
            name: 'Other Services',
            url: '/students',
          },
          {
            name: 'Edit',
            url:
              '/students/eipic-profile/other-services/edit' +
              this.otherServicesId,
          },
        ],
      };
    } else {
      breadcrumb = {
        title: 'Create Other Services',
        breadcrumbs: [
          {
            name: 'Other Services',
            url: '/students',
          },
          {
            name: 'Create',
            url:
              '/students/eipic-profile/other-services/create' +
              this.otherServicesId,
          },
        ],
      };
    }

    this.breadcrumbService.breadcrumb$.next(breadcrumb);
  }

  public submit(): void {
    this.preSchool.markAllAsTouched();

    if (
      !this.preSchool.valid ||
      !this.occupationTherapy.valid ||
      !this.speechAndLanguageTherapy.valid ||
      !this.others.valid
    ) {
      openSnackBar('Please fill in all required fields', this.snackBar);
      return;
    }

    const payload = {
      action: EipicProfileAction.ACTION_UPDATE_EIPIC_PROFILE,
      other_services: {
        pre_school: this.preSchool.value,
        occupation_therapy: this.occupationTherapy.value,
        speech_and_language_therapy: this.speechAndLanguageTherapy.value,
        others: this.others.value,
      },
    };

    this.apiEipicProfile.update(this.otherServicesId, payload).subscribe({
      next: (res) => {
        this.location.back();
        openSnackBar('Other Services update successfully', this.snackBar);
      },
      error: (err) => {
        openSnackBar(err.error.message, this.snackBar);
      },
    });
  }

  public onBack(): void {
    this.location.back();
  }
}
