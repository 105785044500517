import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'not-found',
  template: `<div class="flex flex-col justify-center">
    <div class="flex items-center justify-center">
      <img
        src="assets/images/not-found/not-found.svg"
        class="{{ width }} {{ height }}"
      />
    </div>
    <div class="flex justify-center mt-4">
      {{ text }}
    </div>
  </div>`,
})
export class NotFoundComponent implements OnInit {
  @Input() text: string = 'Data not found';
  @Input() width: string = 'w-full';
  @Input() height: string = 'h-auto';

  ngOnInit(): void {}
}
