import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { ApiSetting } from '../api-setting.service';

export interface Setting {
  id: string;
  key: string;
  value: string;
  notes: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private api: ApiSetting) {}

  settings$ = new ReplaySubject<Setting[]>();

  getAll() {
    return this.api.list({}).pipe(
      tap((settings) => {
        this.settings$.next(settings.data);
      })
    );
  }
}
