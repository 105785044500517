import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { authBackgroundImg } from 'app/modules/auth/assets.auth';
import { environment } from 'environments/environment';
import { Observable, catchError, of, switchMap, throwError, tap } from 'rxjs';
import { appConfig } from '../config/app.config';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private base = '';
  private baseWeb = '';
  protected headers = {};
  public isOTPEnabled = appConfig.enableOTP;
  public authBg = authBackgroundImg || 'assets/images/logo/arc-logo.png';

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {
    this.base = environment.apiUrl;
    this.baseWeb = environment.apiUrl.endsWith('/api')
      ? environment.apiUrl.slice(0, -4) // Remove '/api' from the end
      : environment.apiUrl; // Use as is if '/api' is not present
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', `Bearer ${this.accessToken}`);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  get getCsrfToken(): string {
    return localStorage.getItem('csrfToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resend Token
   *
   * @param token
   */
  resendToken(token: string, email: string): Observable<any> {
    return this._httpClient.post(this.base + '/resend-login-otp', {
      refresh_otp: token,
      email: email,
    });
  }

  /**
   * Verify Token
   *
   * @param token
   * @param email
   */
  verifyToken(token: string, email: string): Observable<any> {
    return this._httpClient
      .post(this.base + '/verify-web-login', {
        email: email,
        token: token,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.data.token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.data.user;

          // Return a new observable with the response
          return of(response.data);
        })
      );
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    const payload = {
      email: email,
    };

    return this._httpClient.post(this.base + '/reset-password-token', payload);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(payload: {}): Observable<any> {
    return this._httpClient.post(this.base + '/reset-password', payload);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post(
        this.base + (!this.isOTPEnabled ? '/login' : '/web-login'),
        credentials
      )
      .pipe(
        switchMap((response: any) => {
          // // Store the access token in the local storage
          // this.accessToken = response.data.token;

          // // Set the authenticated flag to true
          // this._authenticated = true;

          // // Store the user on the user service
          // this._userService.user = response.data.user;

          // Return a new observable with the response
          return of(response.data);
        })
      );
  }

  getCSRF(): Observable<any> {
    return this._httpClient
      .get<any>(`${this.baseWeb}/csrf-token`, { withCredentials: true })
      .pipe(
        tap((response) => {
          if (response.csrf_token) {
            localStorage.setItem('csrfToken', response.csrf_token);
          }
        }),
        catchError((error) => {
          console.error('Error fetching CSRF token:', error);
          localStorage.removeItem('accessToken');
          return of(false);
        })
      );
  }

  getHeaders(): HttpHeaders {
    const csrfToken = localStorage.getItem('csrfToken') || '';
    return new HttpHeaders({
      'X-CSRF-TOKEN': csrfToken,
      Authorization: `Bearer ${this.accessToken}`,
      'X-Skip-Interceptor': 'true',
    });
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // First, get the CSRF token
    return this.getCSRF().pipe(
      switchMap(() => {
        const csrfToken = localStorage.getItem('csrfToken') || '';

        // Now proceed with the sign-in using the token
        return this._httpClient
          .post(this.base + '/me', {
            headers: this.getHeaders(),
            withCredentials: true, // Ensure the request sends cookies
          })
          .pipe(
            catchError(() => {
              // Remove the access token and CSRF token from local storage
              localStorage.removeItem('accessToken');
              localStorage.removeItem('csrfToken');
              window.location.reload();
              return of(true);
            }),
            switchMap((response: any) => {
              // Replace the access token with the new one if it's available on
              // the response object.
              //
              // This is an added optional step for better security. Once you sign
              // in using the token, you should generate a new one on the server
              // side and attach it to the response object. Then the following
              // piece of code can replace the token with the refreshed one.
              if (response.accessToken) {
                this.accessToken = response.accessToken;
              }

              // Set the authenticated flag to true
              this._authenticated = true;

              // Store the user on the user service
              this._userService.user = response;

              // Return true
              return of(true);
            })
          );
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this._httpClient.post(this.base + '/logout', {}).subscribe({
      next: () => {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('csrfToken');

        // Set the authenticated flag to false
        this._authenticated = false;
      },
    });
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //   return of(false);
    // }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
