import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'container',
  template: `<div
    class="bg-white max-w-2xl m-auto mt-6 text-left py-4 px-8 rounded-md shadow-inner shadow-gray-200 border border-gray-200"
  >
    <ng-content></ng-content>
  </div>`,
})
export class ContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
