import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `<button
    mat-button
    (click)="disabled ? '' : onClick.emit()"
    [ngClass]="{
      'opacity-50 cursor-not-allowed pointer-events-none': disabled,
      'bg-blue-500 hover:bg-blue-700 text-white': type === 'primary',
      'bg-gray-200 hover:bg-gray-400 text-black': type === 'secondary',
      'bg-red-500 hover:bg-red-700 text-white': type === 'danger',
      'bg-emerald-500 hover:bg-emerald-700 text-white': type === 'success',
      'bg-white hover:bg-blue-500 border border-blue-500 hover:border-transparent hover:text-white text-blue-500 hover:fill-white':
        type === 'outline',
      'bg-transparent hover:bg-transparent hover:text-blue-700 text-blue-500':
        type === 'link',
      'bg-transparent hover:bg-transparent hover:text-red-700 text-red-500':
        type === 'link-danger'
    }"
    class="font-bold rounded inline-flex items-center"
    [type]="buttonType"
    [disabled]="disabled"
  >
    <span
      class="py-2 px-4 flex items-center"
      [ngClass]="{
        'text-white':
          type === 'primary' || type === 'danger' || type === 'success',
        'text-black': type === 'secondary',
        'hover:text-white text-blue-500': type === 'outline'
      }"
    >
      <mat-icon
        *ngIf="prefixIcon"
        svgIcon="{{ prefixIcon }}"
        [ngClass]="{
          'text-white':
            type === 'primary' || type === 'danger' || type === 'success',
          'text-black': type === 'secondary',
          'hover:fill-white fill-blue-500': type === 'outline',
          'fill-blue-500 hover:fill-blue-700 text-blue-500 hover:text-blue-700':
            type === 'link',
          'fill-red-500 hover:fill-red-700 text-red-500 hover:text-red-700':
            type === 'link-danger'
        }"
        class="mr-2"
      ></mat-icon>
      <div>{{ text }}</div>
    </span>
  </button>`,
})
export class ButtonComponent implements OnInit {
  @Input() text: string = 'Submit';
  @Input() type: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() prefixIcon: string = '';
  @Input() buttonType: string = 'button';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {}
}
