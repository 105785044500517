import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'text-input',
  template: `
    <div class="mb-4 form-group">
      <label
        [for]="controlName"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
        <span *ngIf="isRequired() && label !== null" class="text-red-500">
          *
        </span>
      </label>
      <input
        [type]="type"
        [placeholder]="placeholder"
        [formControl]="controlName"
        [id]="controlName"
        [name]="controlName"
        [maxlength]="maxLength"
        class="w-full border h-9 border-gray-300 py-2 px-4 rounded-md form-control"
        [ngClass]="{
          'bg-gray-100': controlName.disabled,
          'hide-spin-button': hideSpinButton
        }"
        [class.is-invalid]="controlName.invalid && controlName.touched"
      />
      <form-validator [controlName]="controlName"></form-validator>
    </div>
  `,
  styles: [
    `
      .hide-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
      }

      .hide-spin-button::-webkit-inner-spin-button,
      .hide-spin-button::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0; /* Optional: If you want to remove the margin */
        -moz-appearance: textfield; /* For Mozilla Firefox */
      }
    `,
  ],
})
export class TextInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = '';
  @Input() maxLength: number;
  @Input() hideSpinButton: boolean = false;

  @HostListener('input', ['$event']) onInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length > this.maxLength) {
      this.controlName.setValue(inputValue.slice(0, this.maxLength), {
        emitEvent: false,
      });
    }
  }

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }

  ngOnInit(): void {
    if (this.maxLength) {
      this.controlName.addValidators([Validators.maxLength(this.maxLength)]);
    }
  }
}
