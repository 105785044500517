import { Component, Input, OnInit } from '@angular/core';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseConfigService } from '@fuse/services/config';
import {
  BreadcrumbItem,
  BreadcrumbService,
} from 'app/services/breadcrumb.service';

@Component({
  selector: 'app-header',
  template: `<div
    class="flex gap-x-5 items-center justify-start px-4 py-3 bg-white border-b border-gray-200 sm:px-6"
  >
    <div class="item-center">
      <button
        class=" hover:bg-gray-100 mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
        (click)="toggleNavigation('mainNavigation')"
      >
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </div>

    <div>
      <div class="flex items-center mb-2" *ngIf="breadcrumbs">
        <span
          *ngFor="
            let breadcrumb of breadcrumbs.breadcrumbs;
            index as indexOfelement;
            last as isLast
          "
        >
          <a
            [routerLink]="breadcrumb.url"
            [ngClass]="
              indexOfelement == breadcrumbs.breadcrumbs.length - 1
                ? 'text-blue-600'
                : 'text-gray-400'
            "
            class="text-sm"
            >{{ breadcrumb.name }}</a
          >
          <mat-icon
            *ngIf="!isLast"
            id="separator"
            class="h-[12px] min-h-[12px] fill-gray-500"
            svgIcon="mat_solid:arrow_forward_ios"
          ></mat-icon>
        </span>
      </div>
      <p class="text-[20px] font-semibold">{{ breadcrumbs.title }}</p>
    </div>
  </div>`,
})
export class HeaderComponent implements OnInit {
  breadcrumbs: BreadcrumbItem;
  layout: string = 'futuristic';

  constructor(
    private breadcrumbService: BreadcrumbService,
    private _fuseNavigationService: FuseNavigationService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumb$.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
