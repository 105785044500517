import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'text-area',
  template: `<div class="mb-4 form-group">
    <label [for]="controlName" class="block text-sm font-medium text-gray-700"
      >{{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>
    <textarea
      [placeholder]="placeholder"
      [formControl]="controlName"
      [id]="controlName"
      [name]="controlName"
      class="mt-1 w-full border border-gray-300 py-2 px-4 rounded-md form-control"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
    ></textarea>
    <form-validator [controlName]="controlName"></form-validator>
  </div>`,
})
export class TextAreaComponent implements OnInit {
  @Input() label: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = '';

  ngOnInit(): void {}

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
