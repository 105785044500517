<div
  class="relative hidden md:flex flex-auto items-center justify-center w-full h-screen overflow-hidden dark:border-l"
  [ngClass]="{
    'bg-primary p-16 lg:px-28': authBg === defaultAuthBg
  }"
>
  <ng-container *ngIf="authBg === defaultAuthBg">
    <!-- Background - @formatter:off -->
    <!-- Rings -->
    <svg
      class="absolute inset-0 pointer-events-none"
      viewBox="0 0 960 540"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMax slice"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        class="text-gray-700 opacity-25"
        fill="none"
        stroke="currentColor"
        stroke-width="100"
      >
        <circle r="234" cx="196" cy="23"></circle>
        <circle r="234" cx="790" cy="491"></circle>
      </g>
    </svg>
    <!-- Dots -->
    <svg
      class="absolute -top-16 -right-16 text-white"
      viewBox="0 0 220 192"
      width="220"
      height="192"
      fill="none"
    >
      <defs>
        <pattern
          id="837c3e70-6c3a-44e6-8854-cc48c737b659"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect
        width="220"
        height="192"
        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
      ></rect>
    </svg>
    <!-- @formatter:on -->
    <!-- Content -->
    <div class="z-10 relative w-full max-w-2xl">
      <div class="text-7xl font-bold leading-none text-gray-100">
        <div>Welcome to</div>
        <div>ARC(S) EIP Student Information System</div>
      </div>
      <div class="mt-6 text-lg tracking-tight leading-6 text-white">
        Manage students, e-admission, class allocation, event allocation,
        attendance, etc in one portal.
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="authBg !== defaultAuthBg">
    <img
      [src]="authBg"
      alt="WeCAN ARCs"
      class="bg-cover bg-fixed h-full w-full object-cover"
    />
  </ng-container>
</div>
