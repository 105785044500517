export enum AdmissionStatus {
  NEW = 'NEW',
  WAITING_SUBMISSION = 'WAITING_SUBMISSION',
  PARENTS_SUBMITTED = 'PARENTS_SUBMITTED',
  SUBMISSION_EXPIRED = 'SUBMISSION_EXPIRED',
  ASSESSED_ELIGIBLE = 'ASSESSED_ELIGIBLE',
  ASSESSED_NOT_ELIGIBLE = 'ASSESSED_NOT_ELIGIBLE',
  ASSESSED_WAITLIST = 'ASSESSED_WAITLIST',
  PLACEMENT_DRAFT = 'PLACEMENT_DRAFT',
  PLACEMENT_OFFERED = 'PLACEMENT_OFFERED',
  PLACEMENT_REJECTED = 'PLACEMENT_REJECTED',
  PLACEMENT_ACCEPTED = 'PLACEMENT_ACCEPTED',
  PLACEMENT_EXPIRED = 'PLACEMENT_EXPIRED',
  CANCELED = 'CANCELED',
}

export enum ShowedAdmissionStatus {
  NEW_REFERRAL = 'REFERRED',
  WAITLIST_FOR_NEEDS_ASSESSMENT = 'PENDING SCREENING',
  WAITLIST_FOR_PLACEMENT = 'SCREENED',
  CANCELED = 'WITHDRAWN',
  PENDING_UPDATE = 'ON HOLD',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
}
