import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { Staff } from './models/staff';

@Injectable({
  providedIn: 'any',
})
export class ApiStaff extends Api<Staff> {
  constructor(http: HttpClient) {
    super(http);
    this.resource = 'staff';
  }
}
