import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'select-input',
  template: `<div class="mb-4 form-group">
    <label [for]="controlName" class="block text-sm font-medium text-gray-700"
      >{{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>
    <select
      [formControl]="controlName"
      [id]="controlName"
      [name]="controlName"
      class="w-full border border-gray-300 py-2 px-4 rounded-md form-control"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
    >
      <option [value]="null" disabled selected>{{ placeholder }}</option>
      <option
        *ngFor="let option of options; index as index"
        [value]="optionValue ? option[optionValue] : option"
      >
        {{ optionLabel ? option[optionLabel] : option }}
      </option>
    </select>
    <p *ngIf="helperText" class="text-sm italic ml-2">{{ helperText }}</p>
    <form-validator [controlName]="controlName"></form-validator>
  </div>`,
})
export class SelectInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = 'Select an option';
  @Input() controlName: FormControl = new FormControl('');
  @Input() options = [];
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Input() helperText: string;

  ngOnInit(): void {}

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
