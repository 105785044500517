<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="bg-[#111625] print:hidden text-white"
  [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20">
      <img class="w-8" src="assets/images/logo/arc-logo.png" />
    </div>
  </ng-container>

  <ng-container fuseVerticalNavigationFooter>
    <div
      class="md:visible invisible absolute right-0 bottom-5 w-8 h-10 bg-gray-200 rounded-tl-full rounded-bl-full flex justify-center items-center cursor-pointer"
      (click)="expandNavigation()"
    >
      <mat-icon
        class="scale-75 text-black"
        [svgIcon]="'heroicons_outline:arrow-right'"
      ></mat-icon>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <!-- Navigation toggle button -->
  <app-header></app-header>

  <!-- Components -->
  <!-- <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
    <search [appearance]="'bar'"></search>
    <notifications></notifications>
  </div> -->

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
