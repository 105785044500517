import { Component, Input } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'auth-bg',
  templateUrl: './auth-bg.component.html',
})
export class AuthBgComponent {
  @Input() public defaultAuthBg: string = 'assets/images/logo/arc-logo.png';
  @Input() public authBg: string = this.defaultAuthBg;

  constructor(private authService: AuthService) {
    this.authBg = authService.authBg ?? this.defaultAuthBg;
  }
}
