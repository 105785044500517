import { Component, Input } from '@angular/core';
import { EMAIL, PHONE_NUMBER } from 'app/shared/utils/contact-info';

@Component({
  selector: 'contact-us',
  template: `
    <div>
      <p class="text-md">
        {{ beginText }}, please contact Ms Azrini Sukaimi at {{ phone }} or
        email at
        <a class="text-blue-600 cursor-pointer underline" [href]="mailto">
          {{ email }}
        </a>
      </p>
    </div>
  `,
})
export class ContactUsComponent {
  @Input() public beginText: string =
    'Should you need to any assistance or clarifications';

  public phone: string = PHONE_NUMBER;
  public email: string = EMAIL;
  public mailto: string = 'mailto:' + EMAIL;

  constructor() {}
}
