// file-upload.component.ts
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-size',
  template: `<!-- file-upload.component.html -->

    <p class="text-sm text-gray-500 mt-1 text-center">
      Maximum file size: {{ dropzoneOptions.maxFilesize }}MB
    </p>`,
})
export class FileSizeUploadComponent implements OnInit {
  dropzoneOptions = {
    maxFilesize: 5,
  };

  onSelect(event: any) {
    // Handle file selection logic here
  }

  ngOnInit(): void {}
}
