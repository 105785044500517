import { DatePipe } from '@angular/common';

export const dateFormatter = (
  datePipe: DatePipe,
  date: string,
  format: string = 'dd/MM/yyyy'
): string => {
  datePipe = new DatePipe('en-US');
  return datePipe.transform(date, format);
};
