import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class IdleDetectionService {
  private idleTimer: number;
  private idleTime = 30 * 60 * 1000; // 30 minutes in milliseconds

  constructor(private authService: AuthService, private router: Router) {
    this.resetIdleTimer();
  }

  private resetIdleTimer() {
    clearTimeout(this.idleTimer);

    // save last date time of idle
    localStorage.setItem('lastIdle', Date.now().toString());

    this.idleTimer = setTimeout(() => this.logoutUser(), this.idleTime);
  }

  private logoutUser() {
    // check last idle time
    const lastReset = localStorage.getItem('lastIdle');

    // check if last reset > idleTime
    if (lastReset && Date.now() - parseInt(lastReset, 10) > this.idleTime) {
      // Sign out
      this.authService.signOut().subscribe({
        next: () => {
          this.router.navigate(['sign-in']);
        },
      });
    }
  }

  // Add event listeners to track user activity
  public trackUserActivity() {
    window.addEventListener('mousemove', () => this.resetIdleTimer());
    window.addEventListener('keydown', () => this.resetIdleTimer());
  }
}
