import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { merge } from 'lodash-es';

export type DialogCloseResult = 'confirmed' | 'cancelled' | undefined;

@Injectable()
export class FuseConfirmationService
{
    private _defaultConfig: FuseConfirmationConfig = {
        title      : 'Confirm action',
        message    : 'Are you sure you want to confirm this action?',
        icon       : {
            show : true,
            name : 'heroicons_outline:exclamation',
            color: 'warn'
        },
        actions    : {
            confirm: {
                show : true,
                label: 'Confirm',
                color: 'warn'
            },
            cancel : {
                show : true,
                label: 'Cancel'
            }
        },
        dismissible: false
    };

    /**
     * Constructor
     */
    constructor(
        private _matDialog: MatDialog
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent, DialogCloseResult>
{
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Transform userConfig into a MatDialogConfig object
    const dialogConfig: MatDialogConfig = {
        autoFocus   : false,
        disableClose: !userConfig.dismissible,
        data        : userConfig,
        panelClass  : 'fuse-confirmation-dialog-panel'
    };

    // Open the dialog
    return this._matDialog.open<FuseConfirmationDialogComponent, DialogCloseResult>(FuseConfirmationDialogComponent, dialogConfig);
}
}
