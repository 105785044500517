export enum PermissionList {
  // ADMISSION
  VIEW_ADMISSION = 'view admission',
  CREATE_ADMISSION_INVITATION = 'create admission invitation',
  SUBMIT_E_ADMISSION = 'submit e-admission',
  ADMISSION_ASSESSMENT = 'admission assessment',
  ADMISSION_PLACEMENT = 'admission placement',
  ADMISSION_PLACEMENT_DECISION = 'admission placement decision',
  DELETE_ADMISSION = 'delete admission',
  CANCEL_ADMISSION = 'cancel admission',

  // AUDIT
  VIEW_AUDIT = 'view audit',

  // STAFF
  VIEW_STAFF = 'view staff',
  CREATE_STAFF = 'create staff',
  UPDATE_STAFF = 'update staff',
  DELETE_STAFF = 'delete staff',

  // ATTENDANCE
  VIEW_ATTENDANCE = 'view attendance',
  CREATE_ATTENDANCE = 'create attendance',
  UPDATE_ATTENDANCE = 'update attendance',
  DELETE_ATTENDANCE = 'delete attendance',

  // SCHEDULE
  VIEW_SCHEDULE = 'view class session',
  CREATE_SCHEDULE = 'create class session',
  UPDATE_SCHEDULE = 'update class session',
  ASSIGN_TEACHER_TO_SCHEDULE = 'assign teacher to class session',
  ASSIGN_STUDENT_TO_SCHEDULE = 'assign student to class session',
  TRANSFER_STUDENT = 'transferring student to other class session',
  ASSIGN_CASE_LOAD_TEACHER = 'assign case load teacher',
  ASSIGN_PRIMARY_TEACHER = 'assign form teacher',
  DELETE_SCHEDULE = 'delete class session',

  // STUDENT
  VIEW_ALL_STUDENT = 'view all student',
  VIEW_RELATED_STUDENT = 'view related student',
  UPDATE_STUDENT = 'update student',
  VIEW_EIPIC_PROFILE = 'view eipic profile',
  VIEW_DS_PLUS_PROFILE = 'view ds plus profile',

  // DS+ Admission
  VIEW_DS_PLUS_ADMISSION = 'view ds+ admission',
  CREATE_DS_PLUS_ADMISSION = 'create ds+ admission',
  UPDATE_DS_PLUS_ADMISSION = 'update ds+ admission',

  // ACTIVITY RECORD / COMMUNICATION LOG
  VIEW_ACTIVITY_RECORD = 'view activity record',
  CREATE_ACTIVITY_RECORD = 'create activity record',
  UPDATE_ACTIVITY_RECORD = 'update activity record',
  DELETE_ACTIVITY_RECORD = 'delete activity record',

  // CALENDAR
  VIEW_CALENDAR = 'view calendar',
  CREATE_CALENDAR = 'create calendar',
  UPDATE_CALENDAR = 'update calendar',
  DELETE_CALENDAR = 'delete calendar',

  // ACADEMIC YEAR
  VIEW_ACADEMIC_YEAR = 'view academic year',
  CREATE_ACADEMIC_YEAR = 'create academic year',
  UPDATE_ACADEMIC_YEAR = 'update academic year',
  DELETE_ACADEMIC_YEAR = 'delete academic year',

  // TERM
  VIEW_SCHOOL_TERM = 'view school term',
  CREATE_SCHOOL_TERM = 'create school term',
  UPDATE_SCHOOL_TERM = 'update school term',
  DELETE_SCHOOL_TERM = 'delete school term',

  // EMAIL BROADCAST
  VIEW_EMAIL_BROADCAST = 'view broadcast',
  CREATE_EMAIL_BROADCAST = 'create broadcast',
  UPDATE_EMAIL_BROADCAST = 'update broadcast',
  DELETE_EMAIL_BROADCAST = 'delete broadcast',

  // FINANCE
  VIEW_FINANCE = 'view finance',
  CREATE_FINANCE = 'create finance',
  UPDATE_FINANCE = 'update finance',
  DELETE_FINANCE = 'delete finance',

  // SETTING
  CREATE_SETTING = 'create setting',
  UPDATE_SETTING = 'update setting',

  // DOCUMENT
  CREATE_DOCUMENT = 'create document',
  UPDATE_DOCUMENT = 'update document',
  DELETE_DOCUMENT = 'delete document',

  // ROLE
  VIEW_ROLE = 'view role',
  CREATE_ROLE = 'create role',
  UPDATE_ROLE = 'update role',
  DELETE_ROLE = 'delete role',

  // WITHDRAWAL
  VIEW_WITHDRAWAL = 'view withdrawal',
  CREATE_WITHDRAWAL = 'create withdrawal',
  UPDATE_WITHDRAWAL = 'update withdrawal',
  RESEND_WITHDRAWAL = 'resend withdrawal',
  UPDATE_WITHDRAWAL_FEE = 'update withdrawal fee',
  WITHDRAWAL_DECISION = 'withdrawal decision',
  DELETE_WITHDRAWAL = 'delete withdrawal',

  // EIPIC UPDATE REPORT
  VIEW_EIPIC_UPDATE_REPORT = 'view eipic update report',
  CREATE_EIPIC_UPDATE_REPORT = 'create eipic update report',
  UPDATE_EIPIC_UPDATE_REPORT = 'update eipic update report',
  DELETE_EIPIC_UPDATE_REPORT = 'delete eipic update report',

  // DS PLUS SESSION SUMMARY
  VIEW_DS_PLUS_SESSION_SUMMARY = 'view ds+ session summary',
  CREATE_DS_PLUS_SESSION_SUMMARY = 'create ds+ session summary',
  UPDATE_DS_PLUS_SESSION_SUMMARY = 'update ds+ session summary',
  DELETE_DS_PLUS_SESSION_SUMMARY = 'delete ds+ session summary',

  // IEP REPORT
  VIEW_ALL_IEP_REPORT = 'view all iep report',
  VIEW_RELATED_IEP_REPORT = 'view related iep report',
  CREATE_IEP_REPORT = 'create iep report',
  UPDATE_IEP_REPORT = 'update iep report',
  SIGN_IEP_REPORT = 'sign iep report',
  COMMENT_IEP_REPORT = 'comment iep report',
  DELETE_IEP_REPORT = 'delete iep report',

  // PROGRESS REPORT
  VIEW_ALL_PROGRESS_REPORT = 'view all progress report',
  VIEW_RELATED_PROGRESS_REPORT = 'view related progress report',
  CREATE_PROGRESS_REPORT = 'create progress report',
  UPDATE_PROGRESS_REPORT = 'update progress report',
  SIGNING_PROGRESS_REPORT = 'sign progress report',
  COMMENT_PROGRESS_REPORT = 'comment progress report',
  DELETE_PROGRESS_REPORT = 'delete progress report',

  // RISK ASSESSMENT
  VIEW_ALL_RISK_ASSESSMENT = 'view all risk assessment report',
  VIEW_RELATED_RISK_ASSESSMENT = 'view related risk assessment report',
  CREATE_RISK_ASSESSMENT = 'create risk assessment report',
  UPDATE_RISK_ASSESSMENT = 'update risk assessment report',
  DELETE_RISK_ASSESSMENT = 'delete risk assessment report',

  // FAMILY INTERVIEW
  VIEW_FAMILY_INTERVIEW = 'view family interview report',
  CREATE_FAMILY_INTERVIEW = 'create family interview report',
  UPDATE_FAMILY_INTERVIEW = 'update family interview report',
  DELETE_FAMILY_INTERVIEW = 'delete family interview report',

  // ALL ABOUT ME BOOK
  VIEW_ALL_ABOUT_ME_BOOK = 'view all about me book',
  CREATE_ALL_ABOUT_ME_BOOK = 'create all about me book',
  UPDATE_ALL_ABOUT_ME_BOOK = 'update all about me book',
  DELETE_ALL_ABOUT_ME_BOOK = 'delete all about me book',

  // CHAT
  VIEW_CHAT = 'view chat',
  CREATE_CHAT = 'create chat',
  UPDATE_CHAT = 'update chat',
  DELETE_CHAT = 'delete chat',

  // CLASSROOM
  VIEW_CLASSROOM = 'view class',
  CREATE_CLASSROOM = 'create class',
  UPDATE_CLASSROOM = 'update class',
  DELETE_CLASSROOM = 'delete class',

  // PARENT
  VIEW_PARENT = 'view parent',
  CREATE_PARENT = 'create parent',
  UPDATE_PARENT = 'update parent',
  DELETE_PARENT = 'delete parent',

  // USER
  VIEW_USER = 'view user',
  UPDATE_USER = 'update user',

  // CUSTOM REPORT
  GENERATE_CUSTOM_REPORT = 'generate custom report',

  // DS+ DISCUSSION NOTE
  VIEW_DS_PLUS_DISCUSSION_NOTE = 'view ds+ discussion note report',
  CREATE_DS_PLUS_DISCUSSION_NOTE = 'create ds+ discussion note report',
  UPDATE_DS_PLUS_DISCUSSION_NOTE = 'update ds+ discussion note report',
  SIGN_DS_PLUS_DISCUSSION_NOTE = 'sign ds+ discussion note report',
  DELETE_DS_PLUS_DISCUSSION_NOTE = 'delete ds+ discussion note report',
}
