import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'toggle-button',
  template: `<div class="flex w-full">
    <label [for]="id" class="flex items-center cursor-pointer">
      <!-- toggle -->
      <div class="relative">
        <!-- input -->
        <input
          type="checkbox"
          [id]="id"
          class="sr-only"
          [formControl]="controlName"
        />
        <!-- line -->
        <div class="block w-10 h-6 rounded-full background"></div>
        <!-- dot -->
        <div
          class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
        ></div>
      </div>
      <!-- label -->
      <div class="ml-3">{{ label }}</div>
    </label>
  </div>`,
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Input() label: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() id: string = '';

  ngOnInit(): void {}
}
