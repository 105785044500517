import { TextInputComponent } from './../text-input/text-input.component';
import { Component, Input, OnInit } from '@angular/core';
import { snakeToTitle } from '../utils/string-conversion';

@Component({
  selector: 'portal-page',
  template: `
    <div
      class=" w-full h-screen flex justify-center items-center relative overflow-hidden bg-[#F5F5F5]"
    >
      <div class="flex absolute top-8 left-8 items-center">
        <img
          class="w-11 h-11 mr-2"
          src="assets/images/logo/arc-logo.png"
          alt=""
        />
        <img
          class="w-20 h-11"
          src="assets/images/logo/arc-portal-text.svg"
          alt=""
        />
      </div>
      <img
        class="w-90 h-90 bottom-[-120px] left-[-100px] -z--1 opacity-[0.05] absolute"
        src="assets/images/logo/arc-logo.png"
        alt=""
      />
      <div class="w-full sm:w-1/2 flex flex-col sm:pl-10 pr-4 gap-14">
        <ng-content> </ng-content>
      </div>
      <div
        class="w-1/2 h-full sm:flex flex-col relative justify-center items-center bg-[#EAF0FE] hidden"
      >
        <img class="w-3/5 h-full absolute" src="{{ images }}" alt="" />
      </div>
    </div>
  `,
})
export class PagePortal implements OnInit {
  @Input() images: string = 'assets/images/illustrations/portalimg.svg';

  ngOnInit(): void {}
}
