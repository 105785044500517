<div>
  <select
    placeholder="Select a value"
    class="text-blue-600 p-1 border border-blue-500 rounded"
  >
    <option *ngFor="let option of options" [value]="option">
      {{ option }}
    </option>
  </select>
</div>
