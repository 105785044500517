<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative rounded-full">
    <div class="rounded-full h-7 w-7 overflow-hidden">
      <img
        class="object-cover object-center w-full h-full aspect-square"
        *ngIf="showAvatar && user.profile_picture"
        [src]="user.profile_picture | secure | async"
      />
    </div>
    <mat-icon
      class="absolute inset-0 w-full h-full flex justify-center items-center"
      *ngIf="!showAvatar || !user.profile_picture"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
    <span
      class="absolute -bottom-1 -right-1 w-2 h-2 rounded-full"
      [ngClass]="{
        'bg-green-500': user.status === 'online',
        'bg-amber-500': user.status === 'away',
        'bg-red-500': user.status === 'busy',
        'bg-gray-400': user.status === 'not-visible'
      }"
    ></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button
    *ngIf="!!config?.enableResetPassword"
    mat-menu-item
    (click)="changePassword()"
  >
    <mat-icon [svgIcon]="'heroicons_outline:key'"></mat-icon>
    <span>Change Password</span>
  </button>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
