import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'radio-button',
  template: `<div class="mb-2">
    <label class="block text-sm font-medium text-gray-700"
      >{{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>
    <div>
      <mat-radio-group
        aria-label="Select an option"
        [formControl]="controlName"
        (change)="change.emit($event)"
        class="space-x-4"
      >
        <mat-radio-button
          *ngFor="let option of options"
          [value]="optionValue ? option[optionValue] : option.toLowerCase()"
          ><span class="text-sm">{{
            optionLabel ? option[optionLabel] : option
          }}</span></mat-radio-button
        >
      </mat-radio-group>
    </div>
    <form-validator [controlName]="controlName"></form-validator>
  </div>`,
})
export class RadioButtonComponent implements OnInit {
  @Input() label: string = 'Select an option';
  @Input() controlName: FormControl = new FormControl('');
  @Input() options: [] = [];
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {}

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
