<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-[#111625] text-white print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.futuristic"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-6 mb-12">
      <img class="w-14 mr-4" src="assets/images/logo/arc-logo.png" />

      <div class="font-semibold text-lg">
        <p>ARC(S) EIP Student Information System</p>
        <section class="flex gap-2 items-center text-base">
          <p *ngIf="!env.production">(UAT)</p>
          <p *ngIf="!!appVersion">Version {{ appVersion }}</p>
        </section>
      </div>
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationFooter>
    <div
      class="md:visible invisible absolute right-0 w-8 h-10 bg-gray-200 rounded-tl-full rounded-bl-full flex justify-center items-center cursor-pointer"
      (click)="expandNavigation()"
    >
      <mat-icon
        class="scale-75 text-black"
        [svgIcon]="'heroicons_outline:arrow-left'"
      ></mat-icon>
    </div>

    <!-- User -->
    <div class="flex items-center w-full px-6 py-8 border-t">
      <user></user>
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <div
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current opacity-80"
        >
          {{ user.name }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50"
        >
          {{ user.email }}
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <app-header></app-header>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
