import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { AuthBgComponent } from './auth-bg/auth-bg.component';
import { AutocompleteInputComponent } from './autocomplete/autocomplete.component';
import { AvailableSlotComponent } from './available-slot/available-slot.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { ButtonComponent } from './button/button.component';
import { CheckBoxComponent } from './checkbox/checkbox.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContainerComponent } from './container/container.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MonthPickerComponent } from './date-picker/month-picker.component';
import { FileSizeUploadComponent } from './filesize/file-size.component';
import { FormValidatorComponent } from './form-validator/form-validator.component';
import { HeaderComponent } from './header/header.component';
import { PublicHeaderComponent } from './header/public-header.component';
import { InformationPanelComponent } from './information-panel/information-panel.component';
import { LastUpdatedByComponent } from './last-updated-by/last-updated-by.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PagePortal } from './page-portal/portal-page.component';
import { PanelComponent } from './panel/panel.component';
import { SecurePipe } from './pipes/secure.pipe';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { StatusComponent } from './status/status.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { VimeoVideoComponent } from './vimeo-video/vimeo-video';
import { WeeklyScheduleComponentComponent } from './weekly-schedule/weekly-schedule.component';

@NgModule({
  declarations: [
    StatusComponent,
    ButtonComponent,
    HeaderComponent,
    TextInputComponent,
    DatePickerComponent,
    ToggleButtonComponent,
    CheckBoxComponent,
    ContainerComponent,
    ButtonDropdownComponent,
    InformationPanelComponent,
    AttachmentPreviewComponent,
    AvailableSlotComponent,
    PanelComponent,
    WeeklyScheduleComponentComponent,
    LastUpdatedByComponent,
    PagePortal,
    PublicHeaderComponent,
    NotFoundComponent,
    SelectInputComponent,
    FormValidatorComponent,
    TextAreaComponent,
    RadioButtonComponent,
    TimePickerComponent,
    AutocompleteInputComponent,
    MonthPickerComponent,
    FileSizeUploadComponent,
    ContactUsComponent,
    VimeoVideoComponent,
    AuthBgComponent,
    SecurePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    AngularSignaturePadModule,
    NgxMatTimepickerModule,
    MatAutocompleteModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StatusComponent,
    ButtonComponent,
    HeaderComponent,
    TextInputComponent,
    DatePickerComponent,
    ToggleButtonComponent,
    CheckBoxComponent,
    ContainerComponent,
    ButtonDropdownComponent,
    InformationPanelComponent,
    AttachmentPreviewComponent,
    AvailableSlotComponent,
    PanelComponent,
    WeeklyScheduleComponentComponent,
    LastUpdatedByComponent,
    PagePortal,
    PublicHeaderComponent,
    NotFoundComponent,
    SelectInputComponent,
    FormValidatorComponent,
    TextAreaComponent,
    RadioButtonComponent,
    TimePickerComponent,
    MatMomentDateModule,
    AutocompleteInputComponent,
    MonthPickerComponent,
    FileSizeUploadComponent,
    ContactUsComponent,
    VimeoVideoComponent,
    AuthBgComponent,
    SecurePipe,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
})
export class SharedModule {}
