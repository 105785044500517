<div>
  <div class="flex flex-row gap-2 select-none">
    <span
      *ngFor="let day of days"
      class="font-medium text-md w-8 rounded-full aspect-square grid place-items-center"
      [ngClass]="{
        'bg-blue-700 text-white': checkedDays.includes(day.id),
        'bg-slate-200 text-slate-400': !checkedDays.includes(day.id)
      }"
      >{{ day.label }}</span
    >
  </div>
</div>
