import { Component, Input, NgZone } from '@angular/core';

@Component({
  selector: 'last-updated-by',
  templateUrl: './last-updated-by.component.html',
})
export class LastUpdatedByComponent {
  @Input() name: string;
  @Input() date: number;

  constructor() {}
}
