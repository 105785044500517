import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel',
  template: `<div>
    <div class="">
      <div class="w-full py-1 px-4 rounded-t-md font-semibold {{ titleClass }}">
        {{ title }}
      </div>
      <div class="bg-white border border-slate-200 py-4 px-4 rounded-b-md">
        <ng-content></ng-content>
      </div>
    </div>
  </div>`,
})
export class PanelComponent implements OnInit {
  @Input() title: string;
  @Input() titleClass: string = 'bg-indigo-100 text-indigo-500';

  ngOnInit(): void {}
}
