import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'checkbox',
  template: `
    <div class="flex items-center">
      <input
        type="checkbox"
        [id]="id"
        [formControl]="controlName"
        class="form-checkbox rounded-full h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
      />
      <label [for]="id" class="ml-2">
        {{ label }}
      </label>
    </div>
  `,
})
export class CheckBoxComponent implements OnInit {
  @Input() label: string = 'Required';
  @Input() controlName: FormControl = new FormControl('');
  @Input() id: string = '';

  ngOnInit(): void {}
}
