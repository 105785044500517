import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-validator',
  template: `<div *ngIf="controlName.invalid && controlName.touched">
    <div
      *ngIf="controlName.errors.required"
      class="text-xs text-red-500 mt-1 ml-2"
    >
      This field is required.
    </div>
    <div
      *ngIf="controlName.errors.minlength"
      class="text-xs text-red-500 mt-1 ml-2"
    >
      This field must be at least
      {{ controlName.errors.minlength.requiredLength }} characters.
    </div>
    <div
      *ngIf="controlName.errors.maxlength"
      class="text-xs text-red-500 mt-1 ml-2"
    >
      This field cannot be longer than
      {{ controlName.errors.maxlength.requiredLength }} characters.
    </div>
    <div
      *ngIf="controlName.errors.email"
      class="text-xs text-red-500 mt-1 ml-2"
    >
      This field must be a valid email.
    </div>
    <div *ngIf="controlName.errors.min" class="text-xs text-red-500 mt-1 ml-2">
      This field must be at least {{ controlName.errors.min.min }}.
    </div>
    <div *ngIf="controlName.errors.max" class="text-xs text-red-500 mt-1 ml-2">
      This field cannot be greater than {{ controlName.errors.max.max }}.
    </div>
  </div>`,
})
export class FormValidatorComponent implements OnInit {
  @Input() controlName: FormControl;

  ngOnInit(): void {}
}
