import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { dateFormatter } from '../utils/date-format';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'date-picker',
  template: `<div class="mb-4">
    <label [for]="controlName" class="block text-sm font-medium text-gray-700"
      >{{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500"> * </span>
    </label>
    <div
      class="flex h-9 w-full border border-gray-300 px-4 items-center rounded-md"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
    >
      <input
        matInput
        [matDatepicker]="date"
        [placeholder]="placeholder"
        [id]="controlName"
        [name]="controlName"
        [formControl]="controlName"
        [readOnly]="true"
        class="w-full"
      />
      <mat-datepicker-toggle [for]="date" class="ml-auto">
        <mat-icon
          svgIcon="feather:calendar"
          matDatepickerToggleIcon
          class="text-blue-500"
        ></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #date (closed)="onDateSelected()"></mat-datepicker>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="controlName.invalid && controlName.touched"
    >
      <div
        *ngIf="controlName.errors.required"
        class="text-xs text-red-500 mt-1 ml-2"
      >
        This field is required.
      </div>
    </div>
  </div>`,
  providers: [DatePipe],
})
export class DatePickerComponent implements OnInit {
  @Input() label: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = 'DD/MM/YYYY';

  constructor(private datePipe: DatePipe) {}

  public onDateSelected(): void {
    this.controlName.setValue(
      dateFormatter(this.datePipe, this.controlName.value, 'yyyy-MM-dd')
    );

    console.log(this.controlName.value);
  }

  ngOnInit(): void {
    if (this.controlName.value) {
      this.onDateSelected();
    }
  }

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
