<div class="w-full">
  <form class="space-y-4 mt-4 px-4 mb-10" (ngSubmit)="submit()">
    <div>
      <app-panel title="Preschool">
        <div
          *ngFor="let form of preSchool.controls; let i = index"
          class="bg-slate-100 p-4 rounded-lg mb-4"
        >
          <div class="px-4 sm:grid sm:grid-cols-2 sm:gap-x-6">
            <month-picker
              label="Year"
              [controlName]="form.get('year')"
            ></month-picker>
            <text-input
              label="Ratio (teacher:children)"
              [controlName]="form.get('ratio')"
            ></text-input>
            <text-input label="Frequency" [controlName]="form.get('frequency')">
            </text-input>
            <text-input
              label="Provider"
              [controlName]="form.get('provider')"
            ></text-input>
            <text-area
              label="Remarks"
              [controlName]="form.get('remarks')"
            ></text-area>
            <checkbox
              label="Email/Text Done"
              [controlName]="form.get('email_text_done')"
            ></checkbox>
          </div>
          <div class="w-fit ml-auto" *ngIf="preSchool.controls.length > 1">
            <app-button
              type="link-danger"
              text="Remove"
              (click)="removeForm('pre_school', i)"
            ></app-button>
          </div>
        </div>
        <app-button
          type="link"
          text="Add Preschool"
          (click)="addForm('pre_school')"
        ></app-button>
      </app-panel>
    </div>
    <div>
      <app-panel title="Occupation Therapy">
        <div
          *ngFor="let form of occupationTherapy.controls; let i = index"
          class="bg-slate-100 p-4 rounded-lg mb-4"
        >
          <div class="px-4 sm:grid sm:grid-cols-2 sm:gap-x-6">
            <month-picker
              label="Year"
              [controlName]="form.get('year')"
            ></month-picker>
            <text-input
              label="Ratio (teacher:children)"
              [controlName]="form.get('ratio')"
            ></text-input>
            <text-input label="Frequency" [controlName]="form.get('frequency')">
            </text-input>
            <text-input
              label="Provider"
              [controlName]="form.get('provider')"
            ></text-input>
            <text-area
              label="Remarks"
              [controlName]="form.get('remarks')"
            ></text-area>
            <checkbox
              label="Email/Text Done"
              [controlName]="form.get('email_text_done')"
            ></checkbox>
          </div>
          <div
            class="w-fit ml-auto"
            *ngIf="occupationTherapy.controls.length > 1"
          >
            <app-button
              type="link-danger"
              text="Remove"
              (click)="removeForm('occupation_therapy', i)"
            ></app-button>
          </div>
        </div>
        <app-button
          type="link"
          text="Add Occupation Therapy"
          (click)="addForm('occupation_therapy')"
        ></app-button>
      </app-panel>
    </div>
    <div>
      <app-panel title="Speech and Language Therapy">
        <div
          *ngFor="let form of speechAndLanguageTherapy.controls; let i = index"
          class="bg-slate-100 p-4 rounded-lg mb-4"
        >
          <div class="px-4 sm:grid sm:grid-cols-2 sm:gap-x-6">
            <month-picker
              label="Year"
              [controlName]="form.get('year')"
            ></month-picker>
            <text-input
              label="Ratio (teacher:children)"
              [controlName]="form.get('ratio')"
            ></text-input>
            <text-input label="Frequency" [controlName]="form.get('frequency')">
            </text-input>
            <text-input
              label="Provider"
              [controlName]="form.get('provider')"
            ></text-input>
            <text-area
              label="Remarks"
              [controlName]="form.get('remarks')"
            ></text-area>
            <checkbox
              label="Email/Text Done"
              [controlName]="form.get('email_text_done')"
            ></checkbox>
          </div>
          <div
            class="w-fit ml-auto"
            *ngIf="speechAndLanguageTherapy.controls.length > 1"
          >
            <app-button
              type="link-danger"
              text="Remove"
              (click)="removeForm('speech_and_language_therapy', i)"
            ></app-button>
          </div>
        </div>
        <app-button
          type="link"
          text="Add Speech and Language Therapy"
          (click)="addForm('speech_and_language_therapy')"
        ></app-button>
      </app-panel>
    </div>
    <div>
      <app-panel title="Others (Social Skills Group, Private ELS, etc)">
        <div
          *ngFor="let form of others.controls; let i = index"
          class="bg-slate-100 p-4 rounded-lg mb-4"
        >
          <div class="px-4 sm:grid sm:grid-cols-2 sm:gap-x-6">
            <month-picker
              label="Year"
              [controlName]="form.get('year')"
            ></month-picker>
            <text-input
              label="Ratio (teacher:children)"
              [controlName]="form.get('ratio')"
            ></text-input>
            <text-input label="Frequency" [controlName]="form.get('frequency')">
            </text-input>
            <text-input
              label="Provider"
              [controlName]="form.get('provider')"
            ></text-input>
            <text-area
              label="Remarks"
              [controlName]="form.get('remarks')"
            ></text-area>
            <checkbox
              label="Email/Text Done"
              [controlName]="form.get('email_text_done')"
            ></checkbox>
          </div>
          <div class="w-fit ml-auto" *ngIf="others.controls.length > 1">
            <app-button
              type="link-danger"
              text="Remove"
              (click)="removeForm('others', i)"
            ></app-button>
          </div>
        </div>
        <app-button
          type="link"
          text="Add Others"
          (click)="addForm('others')"
        ></app-button>
      </app-panel>
    </div>
    <div class="w-fit mt-6 ml-auto space-x-4">
      <app-button
        type="secondary"
        text="Back"
        (onClick)="onBack()"
      ></app-button>
      <app-button
        buttonType="submit"
        type="primary"
        text="Confirm"
      ></app-button>
    </div>
  </form>
</div>
