<div
  class="flex flex-col justify-center w-full rounded border-2 border-gray-300"
>
  <div
    class="bg-slate-100 border-b-2 border-gray-300 p-4 flex justify-center w-full rounded-t"
  >
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="bg-white w-full rounded">
    <div class="p-4 justify-center">
      <ng-content></ng-content>
    </div>
  </div>
</div>
